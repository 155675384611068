import React, { FC } from 'react';
import { useTranslation } from '$hooks';
import { IWebRef } from '$models';
import { Link } from '$components/elements/link';

type Props = {
    data: IWebRef;
};

export const AudioRef: FC<Props> = (props: Props): JSX.Element => {
    const { data } = props;
    const { translate } = useTranslation();

    return (
        <audio controls>
            <source src={data.uri} type="audio/mpeg" />
            {translate('modals.audioLightBox.downloadLinkPrefix')}
            <Link applyTextStyling={false} href={data.uri}>
                {translate('modals.audioLightBox.downloadLink')}
            </Link>
            {translate('modals.audioLightBox.downloadLinkPostFix')}
        </audio>
    );
};
