import React, { FC } from 'react';
import { useTranslation } from '$hooks';
import { IWebRef } from '$models';
import { Link } from '$components/elements/link';

type Props = {
    data: IWebRef;
};

export const ReadRef: FC<Props> = (props: Props): JSX.Element => {
    const { data } = props;
    const { translate } = useTranslation();

    return (
        <>
            {!!data.uri && (
                <div>
                    {translate('modals.extractLightBox.downloadLinkPrefix')}
                    <Link themedLink href={data.uri}>
                        {translate('modals.extractLightBox.downloadLink')}
                    </Link>
                    {translate('modals.extractLightBox.downloadLinkPostFix')}
                </div>
            )}
        </>
    );
};
