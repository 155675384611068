import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { ICategoryPage, ICMSPage, IFullSearchResult } from '$models';
import { Container, Flex } from '$components/layouts';
import { PageTypes } from '$constants/pages';
import { SearchModule } from '~/modules/filter';
import { usePageData } from '$hooks';
import { Spots } from '$components/spots/spots';
import { Text } from '$components/elements/text';
import { RichText } from '$components/elements/rich-text';
import { SearchContentTypes } from '~/modules/search';

const PRODUCT_LIST_SPOT_NAME = 'Product list';

type Props = {
    initialData?: IFullSearchResult[];
} & ICMSPage;

export const CategoryPage: FC<Props> = memo((props: Props) => {
    const { content, bottomContent, initialData } = props;
    const { pageData, topSpots, bottomSpots, contentSplitterIndex } = usePageData<ICategoryPage>(
        content,
        bottomContent,
        [PageTypes.CategoryPage, PageTypes.CategoryPageAlt],
        PRODUCT_LIST_SPOT_NAME
    );

    return (
        <Container>
            <Text h1 variant="display2" noSpacing>
                {pageData?.navigationTitle}
            </Text>
            {!!topSpots.length && (
                <Spacer>
                    <Spots elements={topSpots} />
                </Spacer>
            )}
            {contentSplitterIndex > -1 && pageData?.id && (
                <SearchModule
                    searchQuery="*"
                    filterId={pageData.id}
                    mode={SearchContentTypes.Products}
                    initialData={initialData}
                    hideOnNoResults
                />
            )}
            <Spots elements={bottomSpots} />
            <Header justifyContent="center">
                <Text h2 noSpacing>
                    {pageData?.heading}
                </Text>
            </Header>
            <CategoryPageContent column alignItems="center">
                <RichText text={pageData?.teaser} />
                <RichText text={pageData?.body} />
            </CategoryPageContent>
        </Container>
    );
});

const Spacer = styled.div(({ theme }) => ({ marginTop: theme.space[6] }));

const CategoryPageContent = styled(Flex)({
    maxWidth: '50rem',
    margin: '0 auto',
});

const Header = styled(Flex)(({ theme }) => ({
    width: '100%',
    position: 'relative',
    marginBottom: theme.space[6],

    '& > *': {
        background: theme.colors.white,
        padding: `0 ${theme.space[6]}`,
    },

    '&:before': {
        content: '""',
        position: 'absolute',
        background: theme.colors.grey15,
        height: '0.0625rem',
        width: '100%',
        top: '50%',
        left: 0,
        right: 0,
        zIndex: -1,
    },
}));
