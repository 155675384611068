import React, { FC, memo, useEffect } from 'react';
import { ICMSPage, INavigation, IStoreDetail } from '$models';
import { usePageData } from '$hooks';
import { PageTypes } from '$constants';
import { Container } from '$components/layouts';
import { Spots } from '$components/spots/spots';
import { Headline } from '$components/elements/headline';
import { StoreDetailContainer } from '$templates/store-detail-page/store-detail-container';
import { useStoreMap } from '$templates/find-store-page/store-map.state';
import { TrustPilotScript } from '$components/scripts/trustpilot';

type Props = ICMSPage & {
    navigation: INavigation;
};

export const StoreDetailPage: FC<Props> = memo((props: Props) => {
    const { content, bottomContent, navigation } = props;
    const { pageData, topSpots, bottomSpots } = usePageData<IStoreDetail>(
        content,
        bottomContent,
        PageTypes.StoreDetailPage
    );

    const { updateImageCollection } = useStoreMap();

    useEffect(() => {
        if (pageData) {
            updateImageCollection(pageData);
        }
    }, []);

    return (
        <>
            <TrustPilotScript />
            <Container>
                <Spots elements={topSpots} />

                {!!pageData && (
                    <>
                        <Headline size={2}>{pageData?.selectedStore.customerName}</Headline>
                        <StoreDetailContainer {...pageData} findStorePath={navigation?.findStore?.path} />
                    </>
                )}

                <Spots elements={bottomSpots} />
            </Container>
        </>
    );
});
