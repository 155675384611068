import React, { FC, memo, useMemo } from 'react';
import { IAuthorIndex } from '$models';
import { Link } from '$components/elements/link';
import { key } from '~/utils';
import { Grid } from '$components/layouts';

type Props = {
    index: IAuthorIndex[];
};

export const AuthorIndex: FC<Props> = memo(({ index }) => {
    const relativeUrl = useMemo(() => index.find((value) => !value.url.startsWith('?')), [index])?.url;

    return (
        <Grid flow="row" columns="repeat(auto-fit, minmax(12px, 1fr))" columnGap="small" rowGap="small">
            {index.map((value) => (
                <Link
                    key={key(value)}
                    themedLink
                    href={value.url.startsWith('?') ? `${relativeUrl}${value.url}` : value.url}
                >
                    {value.name}
                </Link>
            ))}
        </Grid>
    );
});
