import React, { ComponentProps, FC, useMemo } from 'react';
import styled from '@emotion/styled';
import { IAuthorPerson } from '$models';
import { Link } from '$components/elements/link';
import { Grid } from '$components/layouts';
import { mq } from '$lib/helpers';
import { key } from '~/utils';

type Props = {
    persons: IAuthorPerson[];
    tabletColumns?: number;
    desktopColumns?: number;
};

export const AuthorGrid: FC<ComponentProps<typeof AuthorSubGrid>> = ({
    persons,
    desktopColumns = 3,
    tabletColumns = 2,
    ...props
}) => {
    const authors = useMemo(() => {
        const sliceSize = tabletColumns * desktopColumns * 50;
        const iterations = Math.ceil((persons.length ?? 0) / sliceSize);

        const arr: IAuthorPerson[][] = [];

        for (let i = 0; i < iterations; i++) {
            const slice = persons.slice(i * sliceSize, (i + 1) * sliceSize);

            if (slice) arr.push(slice);
        }
        return arr;
    }, [persons, tabletColumns, desktopColumns]);

    return (
        <>
            {authors.map((persons) => (
                <AuthorSubGrid
                    key={key(persons)}
                    persons={persons}
                    desktopColumns={desktopColumns}
                    tabletColumns={tabletColumns}
                    {...props}
                />
            ))}
        </>
    );
};

const AuthorSubGrid: FC<Props> = ({ persons, tabletColumns, desktopColumns }) => {
    const authors = useMemo(
        () =>
            persons.map((person, idx) => (
                <Link themedLink key={`${idx}`} href={person?.url}>
                    {person?.fullname}
                </Link>
            )),
        [persons]
    );

    return (
        <StyledGrid
            flow="row"
            columnGap="small"
            rowGap="small"
            columns="1fr"
            breakpoints={{
                [mq('sm')]: {
                    columns: `repeat(${tabletColumns}, 1fr)`,
                },
                [mq('md')]: {
                    columns: `repeat(${desktopColumns}, 1fr)`,
                },
            }}
        >
            {authors}
        </StyledGrid>
    );
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    marginBottom: theme.space['5'],
}));
