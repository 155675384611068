import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from '@emotion/styled';
import router from 'next/router';
import { red } from 'theme/colors';
import { Loading } from '$components/elements/loading/loading';
import { Flex, Grid } from '$components/layouts';
import { StoreDetailOpeningHours } from '$templates/store-detail-page/store-detail-opening-hours';
import { useSWR, useTranslation } from '$hooks';
import { API_URL, formatString, imageUrl, mq, supports } from '$lib/helpers';
import { toStringParams } from '$lib/helpers/to-string-params.helper';
import { IStoreDetail, IStoreModelIStoreInformation } from '$models';
import { StoreItem, StoreTrustPilotWidget } from '$components/elements/store';
import { templateIds } from '$constants/trustpilot';
import { RouteKeys, useCMSRoute } from '~/store';
import { BackLink } from '$components/elements/back-link';
import { Link } from '$components/elements/link';
import { SvgIcon } from '$components/elements/svg-icon';
import { Map, useGEOJson } from '$components/elements/map';
import { RichText } from '$components/elements/rich-text';
import { Image } from '$components/elements/image';
import { Headline } from '$components/elements/headline';
import { Button } from '$components/elements/button';

type Props = IStoreDetail & {
    findStorePath?: string;
};

export const StoreDetailContainer: FC<Props> = memo((props) => {
    const [searchParams, setSearchParams] = useState<{ lat: number; lng: number; limit: number } | null>(null);
    const { data } = useSWR<IStoreModelIStoreInformation[]>(
        searchParams ? `${API_URL}/scom/api/stores/get?${toStringParams(searchParams)}` : ''
    );
    const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);

    const { selectedStore, markerStandard, markerActive, findStorePath } = props;
    const { featureCollection } = useGEOJson(data);
    const { translate } = useTranslation();
    const descriptionRef = useRef<HTMLDivElement>(null);

    const lnglat = useMemo((): [number, number] => {
        const { address } = selectedStore;
        return [Number(address?.longitude ?? 0), Number(address?.latitude ?? 0)];
    }, [selectedStore]);

    useEffect(() => {
        const [lng, lat] = lnglat;
        setSearchParams({ lat, lng, limit: 15 });
        askForPosition();
    }, []);

    const askForPosition = useCallback(() => {
        if (!supports.geolocation()) {
            return;
        }

        navigator.geolocation.getCurrentPosition(({ coords: { latitude, longitude } }) => {
            setUserLocation({ lat: latitude, lng: longitude });
        });
    }, []);
    const { routes } = useCMSRoute();
    return (
        <>
            <Grid
                flow="row"
                columns="1fr"
                breakpoints={{
                    [mq('lg')]: {
                        columns: '1fr 2fr',
                    },
                }}
                columnGap="medium"
            >
                <Flex column>
                    <LinkContainer>
                        <BackLink onClick={() => router.push(findStorePath || '')}>
                            {translate('findStore.storeDetailGoBack')}
                        </BackLink>
                    </LinkContainer>
                    <StoreItem {...selectedStore} active />
                    <InfoInset column>
                        <Link
                            themedLink
                            href={`https://maps.google.com/maps?saddr=${
                                userLocation ? `${userLocation.lat},${userLocation?.lng}` : 'My+Location'
                            }&daddr=${selectedStore.address?.city}+${selectedStore.address?.streetAndNumber}`}
                            target="_blank"
                            rel="nofollow noreferrer"
                        >
                            {translate('findStore.storeDetailShowRoute')}
                        </Link>
                        {selectedStore.address?.phone &&
                            formatString(translate('findStore.storeDetailPhone'), selectedStore.address.phone)}
                        {selectedStore.email && (
                            <Link themedLink href={`mailto:${selectedStore.email}`}>
                                {selectedStore.email}
                            </Link>
                        )}

                        {!!selectedStore.openingHours?.length && (
                            <StoreDetailOpeningHours openingHours={selectedStore.openingHours} />
                        )}

                        <StoreTrustPilotWidget
                            customerId={selectedStore.customerId}
                            templateId={templateIds.medium}
                            largeView
                        />

                        {descriptionRef?.current && (
                            <ScrollToButton
                                variant="link"
                                onClick={() => descriptionRef.current?.scrollIntoView({ block: 'end' })}
                            >
                                <SvgIcon svg="chevronDown" color={red} size="lg" />
                            </ScrollToButton>
                        )}
                    </InfoInset>
                </Flex>
                {featureCollection ? (
                    <Map
                        featureCollection={featureCollection}
                        markerPaths={{ standard: imageUrl(markerStandard.url), active: imageUrl(markerActive.url) }}
                        activeFeatureId={selectedStore.id}
                        onMarkerClick={(store) =>
                            store &&
                            routes[RouteKeys.StoreDetail] &&
                            router.replace(`${routes[RouteKeys.StoreDetail]}/${store.customerWebName}`)
                        }
                        fitToBounds={false}
                        center={lnglat}
                        zoom={12}
                    >
                        <MapContainer id="map" />
                    </Map>
                ) : (
                    <Loading />
                )}
            </Grid>

            {((selectedStore.customerName && selectedStore.description) ||
                (selectedStore.customerName && selectedStore.imageUrl)) && (
                <StyledHeadline size={3}>{selectedStore.customerName}</StyledHeadline>
            )}

            <Grid
                flow="row"
                columns="1fr"
                breakpoints={{
                    [mq('lg')]: {
                        columns: '2fr 1fr',
                    },
                }}
                columnGap="medium"
            >
                {selectedStore.description && (
                    <RichTextContainer ref={descriptionRef}>
                        <RichText text={selectedStore.description} noPadding />{' '}
                    </RichTextContainer>
                )}
                {selectedStore.imageUrl && (
                    <GridChildImage>
                        <Image
                            height={350}
                            width={475}
                            src={selectedStore.imageUrl}
                            alt={selectedStore.customerName}
                            scale
                        />
                    </GridChildImage>
                )}
            </Grid>
        </>
    );
});

const MapContainer = styled.div({
    width: '100%',
    height: '600px',
});

const GridChildImage = styled.div({
    gridRow: 1,
    [mq('lg')]: {
        gridRow: 'auto',
    },
});

const StyledHeadline = styled(Headline)(({ theme: { space } }) => ({
    paddingTop: space[8],
}));

const RichTextContainer = styled.div(({ theme: { space } }) => ({
    padding: `${space[8]} 0 ${space[12]}`,
}));

const ScrollToButton = styled(Button)(({ theme: { space } }) => ({
    paddingRight: space[4],
    display: 'block',
    marginRight: 'auto',
}));

const LinkContainer = styled.div(({ theme: { space, colors } }) => ({
    paddingBottom: space[6],
    marginBottom: space[6],
    borderBottom: `1px solid ${colors.primaryGreyLight}`,
}));

const InfoInset = styled(Flex)(({ theme: { space } }) => ({
    paddingLeft: space[8],
    '& > *:not(:last-child)': {
        paddingBottom: space[6],
    },
}));
