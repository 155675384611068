import React, { FC } from 'react';
import styled from '@emotion/styled';
import { IWebRef } from '$models';
import { Flex } from '$components/layouts';
import { YoutubeVideo } from '$components/elements/youtube';

type Props = {
    data: IWebRef;
};

export const YoutubeRef: FC<Props> = (props: Props): JSX.Element => {
    const { data } = props;
    return <Container>{!!data?.uri && <YoutubeVideo youtubeId={data.uri} />}</Container>;
};

const Container = styled(Flex)({
    height: 'auto',
    width: '100%',
});
