import React, { FC, memo, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { Position } from 'geojson';
import { IStoreModelIStoreInformation } from '$models';
import { Flex } from '$components/layouts';
import { useTranslation } from '$hooks';
import { mq } from '$lib/helpers';
import { StoreItem, StoreTrustPilotWidget } from '$components/elements/store';
import { templateIds } from '$constants/trustpilot';
import { RouteKeys, useCMSRoute } from '~/store';
import { SvgIcon } from '../svg-icon';
import { Loading } from '../loading';
import { Link } from '../link';
import { Button } from '../button';

type Props = {
    storeCollection?: (IStoreModelIStoreInformation & { distance?: number })[];
    onFetchMore?: () => void;
    displayFetchMore?: boolean;
    isLoading?: boolean;
    userPosition?: Position;
};

export const StoreList: FC<Props> = memo(
    ({ storeCollection, onFetchMore, displayFetchMore, isLoading, userPosition }) => {
        const listRef = useRef<HTMLUListElement>(null);
        const [fetchActive, setFetchActive] = useState(false);
        const { translate } = useTranslation();
        const theme = useTheme();
        const { routes } = useCMSRoute();

        const fetchMore = () => {
            setFetchActive(true);
            onFetchMore && onFetchMore();
        };

        useEffect(() => {
            const { current } = listRef;

            fetchActive && current && (current.scrollTop = current.scrollHeight);
        }, [storeCollection]);

        return (
            <Container column>
                {storeCollection?.length ? (
                    <>
                        <List ref={listRef}>
                            {storeCollection.map((store) => (
                                <ListItem key={store.id}>
                                    <StoreItem {...store} userPosition={userPosition} />
                                    {routes[RouteKeys.StoreDetail] && (
                                        <StyledLink href={`${routes[RouteKeys.StoreDetail]}/${store.customerWebName}`}>
                                            <VisuallyHidden.Root>{store.address?.companyName}</VisuallyHidden.Root>
                                        </StyledLink>
                                    )}
                                    {store.customerId && (
                                        <StoreTrustPilotWidget
                                            customerId={store.customerId}
                                            templateId={templateIds.mini}
                                        />
                                    )}
                                </ListItem>
                            ))}
                        </List>
                        {displayFetchMore && (
                            <StyledButton variant="link" onClick={() => fetchMore()}>
                                {translate('findStore.showMoreStores')}
                                <SvgIcon color={theme.colors.red} svg="chevronDown" size="md" />
                            </StyledButton>
                        )}
                    </>
                ) : isLoading ? (
                    <Loading />
                ) : (
                    translate('findStore.noStoresFound')
                )}
            </Container>
        );
    }
);

const Container = styled(Flex)(({ theme: { space } }) => ({
    paddingRight: space[4],
    [mq('lg')]: {
        width: '33.333%',
    },
}));

const List = styled.ul({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
});

const ListItem = styled.li(({ theme: { colors, space } }) => ({
    position: 'relative',
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    borderBottom: `1px solid ${colors.grey15}`,
    padding: space[2],
    ':after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: 'black',
        zIndex: -1,
        opacity: 0,
        transition: 'opacity 300ms ease-in-out',
    },
    ':hover': {
        ':after': {
            opacity: 0.1,
        },
    },
}));

const StyledLink = styled(Link)({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
});

const StyledButton = styled(Button)(({ theme: { colors, fontWeights } }) => ({
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    color: colors.red,
    textDecoration: 'none',
    fontWeight: fontWeights.regular,
}));
