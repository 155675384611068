import React, { FC, memo, useRef } from 'react';
import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import { ICMSPage, IFullSearchResult, IPersonPage } from '$models';
import { Container, Flex, FlexContent } from '$components/layouts';
import { SearchModule } from '~/modules/filter';
import { PersonData } from './person-data';
import { formatString, isHtml, mq } from '$lib/helpers';
import { Spots } from '$components/spots/spots';
import { PageTypes } from '$constants';
import { usePageData, useTranslation } from '$hooks';
import { Text } from '$components/elements/text';
import { RichText } from '$components/elements/rich-text';
import { ProductMedia } from './product-media';
import { SearchContentTypes } from '~/modules/search';

type Props = {
    initialData?: IFullSearchResult[];
    personId: string;
} & ICMSPage;

export const PersonPage: FC<Props> = memo((props: Props) => {
    const { initialData, personId, content, bottomContent } = props;
    const { translate } = useTranslation();
    const router = useRouter();
    const { pageData, topSpots, bottomSpots } = usePageData<IPersonPage>(content, bottomContent, PageTypes.PersonPage);

    // Scroll to biography logic:
    const biographyRef = useRef<HTMLDivElement>(null);
    const biographyId = 'biography';
    function scrollToBiography(e?: React.MouseEvent) {
        e?.preventDefault?.(); // Prevent browser default, to make scroll smooooth
        biographyRef?.current?.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
            // Added to make the section likable
            // 'BUT delayed because it would overrule the smooth scroll behavior
            router.push({
                hash: biographyId,
            });
        }, 1000);
    }

    return (
        <PersonPageContainer>
            <Spots elements={topSpots} />

            <Text h1>
                {translate('person.details.authorLabel')} {pageData?.personFullName}
            </Text>

            <AnchorButton href={`#${biographyId}`} onClick={scrollToBiography}>
                {formatString(translate('person.details.scrollToBiography'), pageData?.personFullName || '')}
            </AnchorButton>

            {!!initialData?.length && (
                <SearchModule pid={`${personId}`} mode={SearchContentTypes.Products} initialData={initialData} />
            )}
            <PersonBiography ref={biographyRef} id={biographyId}>
                <Text h2>
                    {translate('person.details.authorMoreLabel')} {pageData?.personFullName}
                </Text>
                {!!pageData?.resources?.length && (
                    <MobileImageWrapper>
                        <ProductMedia fullSize images={pageData.resources} />
                    </MobileImageWrapper>
                )}
                <Flex>
                    <FlexContent grow={1} basis={0}>
                        {isHtml(pageData?.personDescription) ? (
                            <RichText text={pageData?.personDescription} noPadding />
                        ) : (
                            <Text>{pageData?.personDescription}</Text>
                        )}
                    </FlexContent>
                    {!!pageData?.resources?.length && (
                        <DesktopImageWrapper>
                            <ProductMedia fullSize images={pageData.resources} />
                        </DesktopImageWrapper>
                    )}
                </Flex>
            </PersonBiography>
            {!!pageData && <PersonData {...pageData} />}
            <Spots elements={bottomSpots} />
        </PersonPageContainer>
    );
});

const PersonPageContainer = styled(Container)(({ theme }) => ({
    paddingBottom: theme.space[4],
}));

const PersonBiography = styled.div(({ theme }) => ({
    marginBottom: theme.space[4],
}));

const MobileImageWrapper = styled.div(({ theme }) => ({
    display: 'block',
    marginBottom: theme.space[4],

    [mq('md')]: {
        display: 'none',
    },
}));

const DesktopImageWrapper = styled.div(({ theme }) => ({
    display: 'none',
    width: '30%',
    paddingLeft: theme.space[6],

    [mq('md')]: {
        display: 'block',
    },
}));

const AnchorButton = styled.a(({ theme }) => ({
    backgroundColor: theme.colors.white,
    border: 'none',
    color: theme.colors.red,
    cursor: 'pointer',
    font: theme.fontFamilies.regular,
    fontSize: theme.fontSizes.md,
    fontWeight: theme.fontWeights.regular,
    outline: 'none',
    textDecoration: 'underline',
}));
