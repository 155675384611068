import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { Container, Flex, FlexContent } from '$components/layouts';
import { ICMSPage, ImageRichTextPage } from '$models';
import { Spots } from '$components/spots/spots';
import { PageTypes } from '$constants';
import { usePageData } from '$hooks';
import { IMAGE_URL, mq } from '$lib/helpers';
import { Text } from '$components/elements/text';
import { RichText } from '$components/elements/rich-text';

type Props = ICMSPage;

export const ImageRichTextpage: FC<Props> = memo((props: Props) => {
    const { content, bottomContent } = props;
    const { pageData, topSpots, bottomSpots } = usePageData<ImageRichTextPage>(content, bottomContent, [
        PageTypes.ImageRichTextPage,
        PageTypes.RichTextPage,
        PageTypes.RichTextPageAlt,
    ]);

    return (
        <Container>
            <Spots elements={topSpots} />
            <PageDataContainer>
                <Text h1>{pageData?.heading}</Text>
                {!!pageData?.image?.url && (
                    <MobileImageWrapper>
                        <StyledImage alt={pageData.image.altText} src={IMAGE_URL + pageData?.image?.url} />
                    </MobileImageWrapper>
                )}

                <Flex>
                    <FlexContent grow={1} basis={0}>
                        {!!pageData?.teaser && (
                            <Text p fontWeight="Regular" fontStyle="italic">
                                {pageData?.teaser}
                            </Text>
                        )}
                        <RichText noPadding text={pageData?.body} />
                    </FlexContent>
                    {!!pageData?.image?.url && (
                        <DesktopImageWrapper>
                            <StyledImage alt={pageData?.image.altText} src={IMAGE_URL + pageData?.image?.url} />
                        </DesktopImageWrapper>
                    )}
                </Flex>
            </PageDataContainer>
            <Spots elements={bottomSpots} />
        </Container>
    );
});

const PageDataContainer = styled.div(({ theme }) => ({
    margin: `${theme.space[6]} 0`,
}));

const MobileImageWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.space[4],
    justifyContent: 'center',
    [mq('md')]: {
        display: 'none',
    },
}));

const DesktopImageWrapper = styled.div(({ theme }) => ({
    display: 'none',
    width: '40%',
    paddingLeft: theme.space[6],
    [mq('md')]: {
        display: 'block',
    },
}));

const StyledImage = styled.img({
    maxWidth: '100%',
    height: 'auto',
    verticalAlign: 'middle',
});
