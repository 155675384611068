import React, { FC, memo, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useIsSSR } from '$hooks';
import { ExtendedWindow } from '$models/global-types';
import { TRUSTPILOT_BUSINESSUNIT_ID, TRUSTPILOT_REVIEW_URL } from '$constants/trustpilot';
import { useSite } from '~/store';
import { Link } from '../link';

type Props = {
    customerId?: string;
    templateId: string;
    largeView?: boolean;
};

export const StoreTrustPilotWidget: FC<Props> = memo(({ customerId, templateId, largeView }) => {
    const ref = useRef<HTMLDivElement>(null);
    const [init, setInit] = useState(false);
    const { isSSR } = useIsSSR();
    const { isTrustpilotInitialized, isTrustpilotBlocked } = useSite();

    useEffect(() => {
        if (isTrustpilotInitialized && customerId && ref.current && !isSSR && (window as ExtendedWindow).Trustpilot) {
            if (!init) {
                (window as ExtendedWindow).Trustpilot.loadFromElement(ref.current);
                setInit(true);
            }
        }
    }, [isTrustpilotInitialized]);
    return (
        <>
            {isTrustpilotInitialized && (
                <div
                    ref={ref}
                    data-locale="da-DK"
                    data-template-id={templateId}
                    data-businessunit-id={TRUSTPILOT_BUSINESSUNIT_ID}
                    data-style-height={largeView ? '130px' : '34px'}
                    data-style-width="240px"
                    data-theme="light"
                    data-location={customerId}
                    data-schema-type="LocalBusiness"
                >
                    <TruspilotLink themedLink href={TRUSTPILOT_REVIEW_URL} target="_blank" rel="noreferrer noopener">
                        Trustpilot
                    </TruspilotLink>
                </div>
            )}
            {isTrustpilotBlocked && (
                <TruspilotLink themedLink href={TRUSTPILOT_REVIEW_URL} target="_blank" rel="noreferrer noopener">
                    Trustpilot
                </TruspilotLink>
            )}
        </>
    );
});

const TruspilotLink = styled(Link)({
    zIndex: 1,
});
