import { useEffect, useState } from 'react';
import { Position } from 'geojson';
import { getDistanceBetweenPoints } from "$services/viamap.service";
import { IOrderIAddress } from "$models";

export const useGEODistance = (
    point: IOrderIAddress | undefined,
    destination: Position | undefined
): number | null => {
    const [distance, setDistance] = useState<number | null>(null);

    useEffect(() => {
        const getPointsAsync = async (): Promise<void> => {
            if (!point ||
                !point.latitude || !point.longitude ||
                !destination) {
                return;
            }

            const origin: Position = [
                parseFloat(point.longitude),
                parseFloat(point.latitude),
            ];
            const routedMeters =  await getDistanceBetweenPoints(origin, destination)

            if (routedMeters) {
                setDistance(Math.round(routedMeters / 1000.0));
            }
        };

        getPointsAsync();
    }, [point, destination]);

    return distance;
};
