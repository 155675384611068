import React, { FC, Fragment } from 'react';
import styled from '@emotion/styled';
import { Grid } from '$components/layouts';
import { useTranslation } from '$hooks';
import { mq } from '$lib/helpers';
import { IPersonPage } from '$models';
import { key } from '~/utils';
import { Link } from '$components/elements/link';

export const PersonData: FC<IPersonPage> = ({
    personFullName,
    personBirthDate,
    personDeathDate,
    knownFor,
    debut,
}: IPersonPage) => {
    const { translate } = useTranslation();

    const shouldShowHeader: boolean =
        !!personFullName || !!personBirthDate || !!personDeathDate || !!knownFor?.length || !!debut?.length;

    return (
        <ContentContainer
            flow="row"
            columns="1fr"
            breakpoints={{
                [mq('lg')]: {
                    columns: '35% 1fr',
                },
            }}
            columnGap="small"
        >
            {shouldShowHeader && <DescriptionHeader>{translate('person.details.detailsLabel')}</DescriptionHeader>}
            <StyledGrid flow="row" columns="1fr 1fr" rowGap="extrasmall">
                {!!personFullName && (
                    <>
                        <div>{translate('person.details.fullNameLabel')}</div>
                        <div>{personFullName}</div>
                    </>
                )}
                {!!personBirthDate && (
                    <>
                        <div>{translate('person.details.birthDateLabel')}</div>
                        <div>{personBirthDate}</div>
                    </>
                )}
                {!!personDeathDate && (
                    <>
                        <div>{translate('person.details.deathDateLabel')}</div>
                        <div>{personDeathDate}</div>
                    </>
                )}
                {!!knownFor?.length && (
                    <>
                        <div>{translate('person.details.knowForLabel')}</div>
                        <div>
                            {knownFor.map((entry, idx) => (
                                <Fragment key={key({ entry, idx })}>
                                    {idx > 0 ? ', ' : ''}
                                    <Link href={entry.url}>{entry.name}</Link>
                                </Fragment>
                            ))}
                        </div>
                    </>
                )}
                {!!debut?.length && (
                    <>
                        <div>{translate('person.details.debutLabel')}</div>
                        <div>{debut.join(', ')}</div>
                    </>
                )}
            </StyledGrid>
        </ContentContainer>
    );
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    '& div': {
        borderBottom: `1px solid ${theme.colors.primaryGreyLight}`,
        padding: `${theme.space[2]} 0`,
    },

    '& div:nth-child(even)': {
        textAlign: 'right',
    },
}));

const ContentContainer = styled(Grid)(({ theme }) => ({
    marginBottom: theme.space[9],
}));

const DescriptionHeader = styled.h6(({ theme }) => ({
    fontFamily: theme.fontFamilies.heading,
    fontSize: theme.fontSizes.h5,
    fontWeight: theme.fontWeights.black,
    lineHeight: 1,

    [mq('md')]: {
        fontSize: theme.fontSizes['2xl'],
    },
}));
