import React, { FC } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from '$hooks';
import { IWebRef } from '$models';
import { API_URL } from '$constants';
import { Link } from '$components/elements/link';

type VideoRefProps = {
    data: IWebRef;
};

export const VideoRef: FC<VideoRefProps> = (props: VideoRefProps): JSX.Element => {
    const { data } = props;
    const { translate } = useTranslation();

    const url = data?.uri?.startsWith('/-/media') ? `${API_URL}${data.uri}` : data.uri;

    return (
        <StyledVideo controls playsInline src={url}>
            <source src={url} type="video/ogg" />
            {translate('modals.videoLightBox.downloadLinkPrefix')}
            <Link applyTextStyling={false} href={url}>
                {translate('modals.videoLightBox.downloadLink')}
            </Link>
            {translate('modals.videoLightBox.downloadLinkPostFix')}
        </StyledVideo>
    );
};

const StyledVideo = styled.video({
    width: '100%',
    height: 'auto',
});
