import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { ICMSPage, IFullSearchResult, ISeriesPage } from '$models';
import { Container, Flex, FlexContent, Grid } from '$components/layouts';
import { SearchModule } from '~/modules/filter';
import { Spots } from '$components/spots/spots';
import { usePageData, useTranslation } from '$hooks';
import { PageTypes } from '$constants';
import { isHtml, mq } from '$lib/helpers';
import { Text } from '$components/elements/text';
import { RichText } from '$components/elements/rich-text';
import { ProductMedia } from './product-media';
import { SearchContentTypes } from '~/modules/search';

type Props = {
    initialData?: IFullSearchResult[];
    sId: string;
} & ICMSPage;

export const SeriesPage: FC<Props> = memo((props: Props) => {
    const { content, bottomContent, initialData, sId } = props;
    const { pageData, topSpots, bottomSpots } = usePageData<ISeriesPage>(content, bottomContent, PageTypes.SeriesPage);
    const { translate } = useTranslation();

    return (
        <Container>
            <Text h1>{pageData?.serieTitle}</Text>
            <Spots elements={topSpots} />
            <SearchModule searchQuery="*" mode={SearchContentTypes.Products} sId={sId} initialData={initialData} />
            <Text h2>{translate('series.details.moreOf', { query: { series: pageData?.serieTitle } })}</Text>
            {!!pageData?.resources?.length && (
                <MobileImageWrapper>
                    <ProductMedia fullSize images={pageData?.resources} webRefs={pageData?.webRefs} />
                </MobileImageWrapper>
            )}
            <Flex>
                <FlexContent grow={1} basis={0}>
                    {isHtml(pageData?.serieSecondSeriesTitleInfo) ? (
                        <RichText text={pageData?.serieSecondSeriesTitleInfo} noPadding />
                    ) : (
                        <Text>{pageData?.serieSecondSeriesTitleInfo}</Text>
                    )}
                </FlexContent>
                {!!pageData?.resources?.length && (
                    <DesktopImageWrapper>
                        <ProductMedia
                            eagerLoad={pageData.eagerLoad}
                            fullSize
                            images={pageData?.resources}
                            webRefs={pageData?.webRefs}
                        />
                    </DesktopImageWrapper>
                )}
            </Flex>
            {!!pageData?.serieTitle && (
                <StyledGrid
                    flow="row"
                    columns="1fr"
                    breakpoints={{
                        [mq('md')]: {
                            columns: '35% 1fr',
                        },
                    }}
                    columnGap="small"
                >
                    <Text h6>{translate('series.details.titleLabel')}</Text>
                    <DescriptionContainer>{pageData?.serieTitle}</DescriptionContainer>
                </StyledGrid>
            )}
            <Spots elements={bottomSpots} />
        </Container>
    );
});

const StyledGrid = styled(Grid)(({ theme }) => ({
    borderBottom: `1px solid ${theme.colors.primaryGreyLight}`,
    marginTop: theme.space[9],
}));

const DescriptionContainer = styled.div({
    textAlign: 'right',
});

const MobileImageWrapper = styled.div(({ theme }) => ({
    display: 'block',
    marginBottom: theme.space[4],

    [mq('sm')]: {
        display: 'none',
    },
}));

const DesktopImageWrapper = styled.div(({ theme }) => ({
    display: 'none',
    width: '30%',
    paddingLeft: theme.space[6],

    [mq('sm')]: {
        display: 'block',
    },
}));
