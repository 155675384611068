import { useMemo } from 'react';
import { FeatureCollection, Point, Feature, GeoJsonProperties } from 'geojson';

type AddressLike<T> = T & { address?: { latitude?: string; longitude?: string } };

export const useGEOJson = <T>(data?: AddressLike<T>[]) => {
    const fieldCollection = useMemo((): { featureCollection: FeatureCollection<Point> } => {
        if (!data?.length) {
            return { featureCollection: { type: 'FeatureCollection', features: [] } };
        }

        const features: Feature<Point, GeoJsonProperties>[] = data
            .filter(({ address }) => Boolean(address?.latitude && address?.longitude))
            .map((feature) => ({
                type: 'Feature',
                properties: feature as GeoJsonProperties,
                geometry: {
                    type: 'Point',
                    coordinates: [Number(feature.address?.longitude), Number(feature.address?.latitude)],
                },
            }));

        return { featureCollection: { type: 'FeatureCollection', features } };
    }, [data]);

    return fieldCollection;
};
