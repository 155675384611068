import React, { FC, memo } from 'react';
import { IAuthorIndexPage, ICMSPage } from '$models';
import { usePageData } from '$hooks';
import { PageTypes } from '$constants';
import { Container } from '$components/layouts';
import { Spots } from '$components/spots/spots';
import { AuthorGrid } from './author-grid/author-grid';
import { AuthorIndex } from './author-index/author-index';
import { Text } from '$components/elements/text';
import { Separator } from '$components/elements/separator';

type Props = ICMSPage;

export const AuthorIndexPage: FC<Props> = memo((props) => {
    const { bottomContent, content } = props;
    const { bottomSpots, pageData, topSpots } = usePageData<IAuthorIndexPage>(
        content.map((content) =>
            'currentIndex' in content && 'index' in content && 'persons' in content
                ? { ...content, spotName: PageTypes.AuthorIndexPage }
                : content
        ),
        bottomContent,
        PageTypes.AuthorIndexPage
    );

    return (
        <Container>
            <Spots elements={topSpots} />

            <Separator />

            <AuthorIndex index={pageData?.index ?? []} />

            <Separator />

            <Text as="h1" variant="display3">
                {pageData?.currentIndex}
            </Text>

            <AuthorGrid persons={pageData?.persons ?? []} />

            <Spots elements={bottomSpots} />
        </Container>
    );
});
