import React, { FC, memo } from 'react';
import styled from '@emotion/styled';
import { Position } from 'geojson';
import { IStoreModelIStoreInformation } from '$models';
import { ClickAndCollectType } from '$constants/click-and-collect';
import { Flex } from '$components/layouts';
import { useTranslation } from '$hooks';
import { formatString } from '$lib/helpers';
import { useStoreMap } from '$templates/find-store-page/store-map.state';
import { Image } from '../image';
import { useGEODistance } from '../map';

type Props = {
    active?: boolean;
    userPosition?: Position;
} & IStoreModelIStoreInformation;

export const StoreItem: FC<Props> = memo((props: Props) => {
    const { translate } = useTranslation();
    const { imageCollection } = useStoreMap();
    const { customerName, address, type, userPosition, active } = props;
    const imageMakerSrc = active ? imageCollection?.markerActive?.url : imageCollection?.markerStandard?.url;
    const clickAndCollectImageSrc =
        type === ClickAndCollectType.clickCollect
            ? imageCollection?.clickCollectActive.url
            : imageCollection?.clickCollectInactive.url;
    const distance = useGEODistance(address, userPosition);

    return (
        <Flex>
            {imageMakerSrc && <Image src={imageMakerSrc} width={20} height={28} />}

            <Address>
                <Headline>{customerName}</Headline>
                <div>{props.storeLocation}</div>
                <div>{address?.streetAndNumber}</div>
                <div>
                    {address?.zip} {address?.city}
                </div>

                {distance && distance > 0 ? (
                    <Note>{formatString(translate('findStore.distance'), distance)}</Note>
                ) : (
                    <Note>{distance === 0 ? translate('findStore.underOneKilometer') : ''}</Note>
                )}
            </Address>

            <IconTextContainer column alignItems="center">
                {clickAndCollectImageSrc && <Image src={clickAndCollectImageSrc} width={64} height={70} />}
                <div>
                    {translate(
                        type === ClickAndCollectType.clickCollect
                            ? 'findStore.freeStoreDelivery'
                            : 'findStore.noFreeStoreDelivery'
                    )}
                </div>
            </IconTextContainer>
        </Flex>
    );
});

const Headline = styled.h4(({ theme: { fontSizes, lineHeights, fontWeights, fontFamilies } }) => ({
    fontSize: fontSizes.sm,
    fontWeight: fontWeights.bold,
    fontFamily: fontFamilies.regular,
    textTransform: 'uppercase',
    lineHeight: lineHeights.base,
}));

const Address = styled.address(({ theme: { space } }) => ({
    padding: `0 0 ${space[2]} ${space[2]}`,
}));

const Note = styled.div(({ theme: { colors, fontSizes } }) => ({
    color: colors.primaryGrey,
    fontStyle: 'italic',
    fontSize: fontSizes.sm,
}));

const IconTextContainer = styled(Flex)(({ theme: { fontSizes, colors } }) => ({
    textAlign: 'center',
    fontSize: fontSizes.xxs,
    color: colors.red,
    marginLeft: 'auto',
    maxWidth: '80px',
}));
