import React, { FC, Fragment, useState } from 'react';
import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { Flex } from '$components/layouts';
import { IImageResource, IWebRef } from '$models';
import { B2CDeliveryMethod } from '$constants';
import { useProductRibbons } from '$hooks';
import { Ribbon } from '$components/elements/product-image/components/ribbon';
import { Splash } from '$components/elements/product-image/components/splash';
import { ProductMediaModalContent } from './product-media-modal-content';
import { key } from '~/utils/key';
import { imageTint } from '$lib/style-helpers';
import { mq } from '$lib/helpers';
import { Carousel } from '$components/elements/carousel';
import { Link } from '$components/elements/link';
import { WebRef } from '$components/elements/web-ref';
import { Modal } from '$components/elements/modal';
import { Image } from '$components/elements/image';

type ProductMediaProps = {
    images?: IImageResource[];
    webRefs?: IWebRef[];
    itemGiftShop?: boolean;
    b2CDeliveryMethod?: B2CDeliveryMethod | undefined;
    isOffer?: boolean;
    splashes?: Array<string> | undefined;
    linkSplashUrl?: string;
    fullSize?: boolean;
    eagerLoad?: boolean;
};

export const ProductMedia: FC<ProductMediaProps> = (props: ProductMediaProps): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const { webRefs, images, itemGiftShop, b2CDeliveryMethod, isOffer, splashes, linkSplashUrl, fullSize, eagerLoad } =
        props;
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);
    const { ribbons } = useProductRibbons(itemGiftShop, b2CDeliveryMethod, isOffer);

    const renderCarouselImage = (image: IImageResource) => (
        <StyledImageContainer fullSize={fullSize}>
            <StyledImage
                priority={eagerLoad}
                height={400}
                width={400}
                onClick={() => setIsOpen(true)}
                src={image.url ?? ''}
            />
        </StyledImageContainer>
    );

    return (
        <>
            <ContentContainer fullSize={fullSize} column alignItems={'center'}>
                {!!ribbons?.length && (
                    <RibbonContainer column alignItems="flex-start">
                        {ribbons?.reverse().map((x, i) => (
                            <Ribbon top key={`${x.text}-${i}`} type={x.color}>
                                {x.text}
                            </Ribbon>
                        ))}
                    </RibbonContainer>
                )}
                <StyledProductMedia fullSize={fullSize}>
                    {!!images?.length && (
                        <>
                            {images.length === 1 ? (
                                <StyledImageContainer fullSize={fullSize}>
                                    <StyledImage
                                        priority={eagerLoad}
                                        height={400}
                                        width={400}
                                        onClick={() => setIsOpen(true)}
                                        src={images?.[0].url ?? ''}
                                    />
                                </StyledImageContainer>
                            ) : (
                                <Carousel
                                    onSlideChange={setSelectedImageIndex}
                                    slidesPerView={1}
                                    spaceBetween={0}
                                    data={images}
                                    keyExtractor={key}
                                    pagination
                                    navigation
                                    template={renderCarouselImage}
                                />
                            )}
                        </>
                    )}
                    {!!splashes?.length && (
                        <SplashContainer>
                            {splashes?.map((x) => (
                                <Fragment key={x}>
                                    {linkSplashUrl ? (
                                        <Link href={linkSplashUrl} expansive>
                                            <SplashLinkContainer>
                                                <Splash priority={eagerLoad} src={x} />
                                            </SplashLinkContainer>
                                        </Link>
                                    ) : (
                                        <Splash src={x} />
                                    )}
                                </Fragment>
                            ))}
                        </SplashContainer>
                    )}
                </StyledProductMedia>
                {!!webRefs?.length && (
                    <WebRefContainer wrap="wrap">
                        {webRefs.map((webRef, index) => (
                            <WebRef key={index} webRef={webRef} />
                        ))}
                    </WebRefContainer>
                )}
            </ContentContainer>
            <Modal show={isOpen} onDismiss={() => setIsOpen(false)}>
                <ProductMediaModalContent images={images} initialImageIndex={selectedImageIndex} />
            </Modal>
        </>
    );
};

const ContentContainer = styled(Flex)<{ fullSize?: boolean }>(({ theme, fullSize }) => ({
    position: 'relative',
    alignSelf: 'flex-start',
    borderRadius: theme.general.borderRadius,
    ...(fullSize ? {} : imageTint(theme)),

    [mq(0, 'md')]: {
        marginBottom: theme.space[9],
    },
}));

const StyledProductMedia = styled.div<{ fullSize?: boolean }>(
    ({ theme }) => ({
        width: '100%',
        height: '100%',
        maxHeight: '500px',
        padding: theme.space[2],

        [mq('lg')]: {
            maxHeight: '600px',
            padding: theme.space[9],
        },
    }),
    ifProp(
        { fullSize: true },
        {
            maxHeight: 'inherit',
            padding: 0,
            [mq('lg')]: {
                maxHeight: 'inherit',
                padding: 0,
            },
        }
    )
);

const WebRefContainer = styled(Flex)(({ theme }) => ({
    marginTop: theme.space[2],
    marginBottom: theme.space[9],
}));

const RibbonContainer = styled(Flex)(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: theme.zIndices[1],
}));

const SplashContainer = styled.div(({ theme }) => ({
    position: 'absolute',
    top: theme.space[8],
    right: theme.space[5],
    display: 'flex',
    height: '100px',
    zIndex: 1,
    width: '35%',
    [mq('md')]: {
        right: theme.space[10],
    },
}));

const SplashLinkContainer = styled.div({
    display: 'flex',
    height: '100%',
    width: '100%',
});

const StyledImageContainer = styled.div<{ fullSize?: boolean }>(
    () => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '& img': {
            width: 'auto',
            height: 'auto',
            maxHeight: '400px',
        },
    }),
    ifProp(
        { fullSize: true },
        {
            '> *': {
                width: '100%',
            },
            '& img': {
                maxHeight: 'inherit',
                width: '100%',
                height: 'auto',
                [mq('md')]: {
                    width: 'auto',
                    height: 'auto',
                },
            },
        }
    )
);

const StyledImage = styled(Image)({
    pointerEvents: 'none',
    [mq('md')]: {
        cursor: 'zoom-in',
        pointerEvents: 'all',
    },
});
