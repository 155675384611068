import React, { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { useWindowSize } from 'react-use';
import { Button } from '../button';
import { SvgIcon } from '../svg-icon';

type Props = {
    expandCTAText?: string;
    collapseCTAText?: string;
    collapsedHeight?: number;
    chevron?: boolean;
};

export const Collapsible: FC<React.PropsWithChildren<Props>> = ({
    expandCTAText,
    collapseCTAText,
    collapsedHeight = 185,
    children,
    ...props
}: React.PropsWithChildren<Props>) => {
    const { width } = useWindowSize();
    const contentRef = useRef<HTMLDivElement>(null);
    const [fullHeight, setFullHeight] = useState(collapsedHeight);
    const [isCollapsible, setIsCollapsible] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        if (contentRef.current && fullHeight < contentRef.current?.clientHeight) {
            setIsCollapsible(true);
            setFullHeight(contentRef.current?.clientHeight);
        } else if (contentRef.current && fullHeight !== contentRef.current?.clientHeight) {
            setIsCollapsible(false);
            setFullHeight(collapsedHeight);
            setIsExpanded(false);
        }
    }, [children, collapsedHeight, fullHeight, width]);

    return (
        <div {...props}>
            <ContentContainer
                collapsedHeight={collapsedHeight}
                isCollapsible={isCollapsible}
                isExpanded={isExpanded}
                contentHeight={fullHeight}
            >
                <div ref={contentRef}>{children}</div>
            </ContentContainer>
            {isCollapsible && (
                <CollapsibleButton
                    variant={props.chevron ? 'custom' : 'link'}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {isExpanded ? collapseCTAText : expandCTAText}
                    {props.chevron && (
                        <Chevron>
                            {isExpanded ? <SvgIcon svg="chevronDown" rotate={180} /> : <SvgIcon svg="chevronDown" />}
                        </Chevron>
                    )}
                </CollapsibleButton>
            )}
        </div>
    );
};

const ContentContainer = styled.div<{
    isExpanded: boolean;
    collapsedHeight: number;
    contentHeight: number;
    isCollapsible: boolean;
}>(({ isExpanded, contentHeight, collapsedHeight, isCollapsible }) => ({
    overflow: 'hidden',
    height: isCollapsible ? (isExpanded ? `${contentHeight}px` : `${collapsedHeight}px`) : 'auto',
    transition: 'height 300ms ease-in-out',
    position: 'relative',

    ':after': {
        content: isCollapsible && !isExpanded ? '""' : 'none',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        height: '50%',
        backgroundImage: 'linear-gradient( rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 100% )',
    },
}));

const CollapsibleButton = styled(Button)(({ theme }) => ({
    marginTop: theme.space[3],
    color: theme.colors.red,
}));

const Chevron = styled.span(({ theme }) => ({
    marginLeft: theme.space[1],
    display: 'flex',
    '& svg': {
        stroke: theme.colors.red,
        fill: theme.colors.red,
        transition: 'transform 300ms ease-in-out',
    },
}));
