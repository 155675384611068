import { Position } from 'geojson';

interface ViaMapRoute {
    index: number;
    fromlatlng: number[];
    travelseconds: number;
    mot: string;
    routepolyline?: any;
    euclideanmeters: number;
    tolatlng: number[];
    routedmeters: number;
}

const token = process.env.VIAMAP_TOKEN;
const buildUrl = (origin: Position, destination: Position) =>
    `https://bogogide.poi.viamap.net/v1/router/?token=${token}&fromlatlng=${origin[1]},${origin[0]}&tolatlngs=${destination[1]},${destination[0]}`;

/**
 * Use VIAMAP router API to get the shortest distance between two points
 *
 * @param origin Position of the origin
 * @param destination Position of the destination
 */
export const getDistanceBetweenPoints = async (origin: Position, destination: Position): Promise<number | null> => {
    const url = buildUrl(origin, destination);
    let routedData;

    try {
        // viamap service can't handle Content-Type: application/json header
        // that is required for Sitecore. This is why we are not using http
        const response = await fetch(url);
        routedData = await response.json();
    } catch (err) {
        return null;
    }

    const routedMeters = routedData?.map((route: ViaMapRoute) => route.routedmeters);

    return routedMeters?.length > 0 ? routedMeters[0] : null;
};

interface ViamapCoordinateResponse {
    query?: string;
    queryType?: string;
    result?: {
        adgangsadresse_id?: string;
        quality?: string;
        washed?: string;
        latlng?: number[];
    };
}

export interface ViamapCoordsModel {
    lat: number;
    lng: number;
}

export const getCoordsFromAddress = async (address: string, zip: string): Promise<ViamapCoordsModel | null> => {
    const url = `https://bogogide.poi.viamap.net/v1/address/?token=${token}&wash=${address}, ${zip}`;
    try {
        const response = await fetch(url);
        if (response.ok) {
            const data: ViamapCoordinateResponse = await response.json();
            return { lat: data.result?.latlng?.[0], lng: data.result?.latlng?.[1] } as ViamapCoordsModel;
        } else {
            return null;
        }
    } catch (err) {
        return null;
    }
};
