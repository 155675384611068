import React, { FC, memo } from 'react';
import { ICMSPage, IFullSearchResult } from '$models';
import { Container } from '$components/layouts';
import { SearchContentTypes } from '~/modules/search';
import { IHelloRetailSearchResponse } from '~/modules/search/interfaces/suggestion';
import { CombinedSearchModule } from '~/modules/filter';

type Props = {
    searchQuery: string;
    searchMode: SearchContentTypes;
    initialData?: IFullSearchResult[];
    initialSortOrderKey?: string;
    initialHelloRetailData?: IHelloRetailSearchResponse[];
} & ICMSPage;

export const SearchPage: FC<Props> = memo((props: Props) => {
    return (
        <Container>
            <CombinedSearchModule
                searchQuery={props.searchQuery}
                mode={props.searchMode}
                initialData={props.initialData}
                initialSortOrderKey={props.initialSortOrderKey}
                initialHelloRetailData={props.initialHelloRetailData}
            />
        </Container>
    );
});
