import React, { FC, useState } from 'react';
import styled from '@emotion/styled';
import { Flex } from '$components/layouts';
import { IImageResource } from '$models';
import { mq } from '$lib/helpers';
import { Image } from '$components/elements/image';
import { Separator } from '$components/elements/separator';
import { Button } from '$components/elements/button';

type Props = {
    images?: IImageResource[];
    initialImageIndex?: number;
};

export const ProductMediaModalContent: FC<Props> = ({ images, initialImageIndex }): JSX.Element => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(initialImageIndex || 0);

    return (
        <>
            {images && (
                <Flex column>
                    <ImageWrapper justifyContent="center" alignItems="center">
                        <Image
                            height={600}
                            width={600}
                            src={images?.[selectedImageIndex].url ?? ''}
                            alt={images?.[selectedImageIndex].altText ?? ''}
                            scale
                        />
                    </ImageWrapper>
                    {images?.length > 1 && (
                        <>
                            <Separator />
                            <ImageSelectorContainer justifyContent="flex-start" gap="medium" wrap="wrap">
                                {images.map((image, index) => (
                                    <StyledButton
                                        key={index}
                                        onClick={() => setSelectedImageIndex(index)}
                                        variant="custom"
                                    >
                                        <ImageSelector isSelected={selectedImageIndex === index}>
                                            <Image height={100} width={100} src={image.url ?? ''} />
                                        </ImageSelector>
                                    </StyledButton>
                                ))}
                            </ImageSelectorContainer>
                        </>
                    )}
                </Flex>
            )}
        </>
    );
};

const ImageWrapper = styled(Flex)({
    width: '70vw',
    [mq('md')]: {
        width: '50vw',
    },
});

const StyledButton = styled(Button)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
        border: 'none',
    },
});

const ImageSelectorContainer = styled(Flex)(({ theme }) => ({
    paddingTop: theme.space[3],
    marginTop: theme.space[3],
    borderTop: theme.general.border,
}));

const ImageSelector = styled(Flex)<{ isSelected: boolean }>(({ theme, isSelected }) => ({
    border: isSelected ? theme.general.border : 'none',
    padding: theme.space[2],
}));
