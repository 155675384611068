import React, { FC, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { darken } from 'color2k';
import { useLockBodyScroll } from 'react-use';
import { ifProp } from 'styled-tools';
import { WebRefType } from '$constants';
import { IWebRef } from '$models';
import { useGtm, useTranslation } from '$hooks';
import { AudioRef } from './components/audio-ref';
import { ReadRef } from './components/read-ref';
import { VideoRef } from './components/video-ref';
import { YoutubeRef } from './components/youtube-ref';
import { circle, cover, flexCenter } from '$lib/style-helpers';
import { mq } from '$lib/helpers';
import { BOISvg, SvgIcon } from '../svg-icon';

type Props = {
    webRef: IWebRef;
};

export const WebRef: FC<Props> = ({ webRef }: Props): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false);
    const { translate } = useTranslation();
    const { trackModalView } = useGtm();
    useLockBodyScroll(isOpen);

    useEffect(() => {
        if (isOpen) {
            const trackType = getWebRefTracking(webRef);
            if (trackType) {
                trackModalView(trackType);
            }
        }
    }, [isOpen]);

    const renderModalContent = (webRef: IWebRef) => {
        switch (webRef.type) {
            case WebRefType.ReadSample:
                return <ReadRef data={webRef} />;
            case WebRefType.SoundSample:
                return <AudioRef data={webRef} />;
            case WebRefType.VideoSample:
                return <VideoRef data={webRef} />;
            case WebRefType.youtube:
                return <YoutubeRef data={webRef} />;
            case WebRefType.vimeo:
            case WebRefType.Unknown:
            default:
                return <></>;
        }
    };

    const getWebRefTitle = (webRef: IWebRef) => {
        switch (webRef.type) {
            case WebRefType.ReadSample:
                return translate('media.webRef.extract');
            case WebRefType.SoundSample:
                return translate('media.webRef.listen');
            case WebRefType.VideoSample:
            case WebRefType.vimeo:
            case WebRefType.youtube:
                return translate('media.webRef.watchVideo');
            case WebRefType.Unknown:
            default:
                return '';
        }
    };
    const getWebRefIcon = (webRef: IWebRef): { svg: keyof typeof BOISvg; padding?: string } => {
        switch (webRef.type) {
            case WebRefType.ReadSample:
                return { svg: 'bookOpen' };
            case WebRefType.SoundSample:
                return { svg: 'headphones' };
            case WebRefType.VideoSample:
            case WebRefType.vimeo:
            case WebRefType.youtube:
                return { svg: 'play', padding: '3px' };
            case WebRefType.Unknown:
            default:
                return { svg: 'cross' };
        }
    };
    const getWebRefTracking = (webRef: IWebRef): string | undefined => {
        switch (webRef.type) {
            case WebRefType.ReadSample:
                return 'extract-modal#';
            case WebRefType.SoundSample:
                return 'audio-modal#';
            case WebRefType.VideoSample:
            case WebRefType.vimeo:
                return 'video-modal#';
            case WebRefType.youtube:
                return 'youtube-modal#';
            case WebRefType.Unknown:
            default:
                return undefined;
        }
    };

    if (!webRef.uri) {
        return <></>;
    }

    const webRefIcon = getWebRefIcon(webRef);

    return (
        <>
            <WebRefButton onClick={() => setIsOpen(true)}>
                <IconContainer>
                    <SvgIcon svg={webRefIcon.svg} css={{ paddingLeft: webRefIcon.padding }} />
                </IconContainer>
                <WebRefTitle>{getWebRefTitle(webRef)}</WebRefTitle>
            </WebRefButton>
            {/* Here we do a lovely custom modal, to work around a chromium bug where translate (which we use in the main modal)
             *  affects the controls of the audio and video tags.
             */}
            {isOpen && (
                <MediaModal>
                    <MediaModalCover onClick={() => setIsOpen(false)} />
                    <WebRefContainer isYoutubeVideo={webRef.type === WebRefType.youtube}>
                        {renderModalContent(webRef)}
                        <MediaModalClose onClick={() => setIsOpen(false)}>
                            <SvgIcon svg="cross" size={15} color="white" />
                        </MediaModalClose>
                    </WebRefContainer>
                </MediaModal>
            )}
        </>
    );
};

const WebRefTitle = styled.div(({ theme }) => ({
    ...theme.mixins.useTextStyle('body'),
}));

const MediaModal = styled.div(({ theme }) => ({
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: theme.zIndices.modal,
}));

const MediaModalCover = styled.div({
    ...cover,
    backgroundColor: 'rgba(0, 0, 0, .4)',
});

const MediaModalClose = styled.button(({ theme }) => ({
    all: 'unset',
    ...flexCenter,
    ...circle(35),
    background: theme.colors.black,
    cursor: 'pointer',
    position: 'absolute',
    right: '-15px',
    top: '-15px',
    zIndex: 1,
    color: theme.colors.white,
}));

const WebRefContainer = styled.div<{ isYoutubeVideo: boolean }>(
    ({ theme }) => ({
        backgroundColor: theme.colors.white,
        borderRadius: theme.general.borderRadius,
        display: 'block',
        maxWidth: '90vw',
        minHeight: '55px',
        padding: theme.space[5],
        position: 'relative',

        video: {
            maxHeight: '500px',
        },
    }),
    ifProp(
        { isYoutubeVideo: true },
        {
            width: '100%',
            maxWidth: '90vw',
            [mq('ml')]: {
                maxWidth: '900px',
            },
        }
    )
);

const WebRefButton = styled.button(({ theme }) => ({
    outline: 'none',
    cursor: 'pointer',
    background: 'none',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: `0 ${theme.space[1]}`,
}));

const IconContainer = styled.div(({ theme }) => ({
    backgroundColor: theme.colors.red,
    borderRadius: '50px',
    width: '50px',
    height: '50px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.fontSizes.lg,
    marginBottom: theme.space[2],

    '& svg path': {
        fill: theme.colors.white,
        stroke: theme.colors.white,
    },
    '&:hover': {
        backgroundColor: darken(theme.colors.red, 0.05),
    },
}));
