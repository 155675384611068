import React, { FC, memo, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { IStoreOpeningHours } from '$models';
import { key } from '~/utils';
import { Text } from '$components/elements/text';
import { Grid } from '$components/layouts';

type Props = {
    openingHours: IStoreOpeningHours[];
};

export const StoreDetailOpeningHours: FC<Props> = memo(({ openingHours }) => {
    const hours = useMemo(() => openingHours.filter((line) => line.active), [openingHours]);
    const theme = useTheme();

    return (
        <>
            {hours.map((day) => (
                <div key={key(day)}>
                    {day.title && <Text size={theme.fontSizes?.sm}>{day.title}</Text>}

                    {day.lines?.map((line) => (
                        <Grid key={key(line)} flow="row" columns="1fr 2fr" rowGap="small" columnGap="small">
                            <Text size={theme.fontSizes?.sm}>{line.title}</Text>
                            <Text size={theme.fontSizes?.sm}>
                                {line.startTime} {line.endTime && `- ${line.endTime}`}
                            </Text>
                        </Grid>
                    ))}
                </div>
            ))}
        </>
    );
});
