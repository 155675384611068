import React, { FC } from 'react';
import Script from 'next/script';
import { useSite } from '~/store';

export const TrustPilotScript: FC = () => {
    const { setTrustpilotInitialized, isTrustpilotInitialized, setTrustpilotBlocked, isTrustpilotBlocked } = useSite();
    return (
        <>
            {!isTrustpilotInitialized && !isTrustpilotBlocked && (
                <Script
                    id="trustpilot"
                    src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
                    onLoad={() => setTrustpilotInitialized(true)}
                    onError={() => setTrustpilotBlocked(true)}
                    strategy="lazyOnload"
                />
            )}
        </>
    );
};
